import { GetServerSideProps } from "next"
import Head from "next/head"
import React, { Fragment, ReactElement } from "react"
import {
  Grid,
  GridItem,
  H1,
  H4,
  HomepagePageHeader,
  HomepageQuote,
  Image,
  LinkCards,
  Markdown,
  Message,
  NewsItems,
  P,
  PageWidthContainer,
  PublicLayout,
  TopScrollButton,
} from "src/components"
import { IFooterData, IHomepage, INewsItem } from "src/services"
import api from "src/services/api"
import { styled } from "src/stitches.config"
import { setCacheControl } from "src/utils/set-cache-control"
import { SSRException } from "src/utils/ssr-exception"

interface Props {
  page: IHomepage
  newsItems: INewsItem[]
  footerData: IFooterData
}

const PageContent = styled("div", {
  padding: "$40 $15",
  backgroundColor: "$white",

  "@mdDown": {
    width: "calc(100% + $30)",
    marginBottom: "$40",
    marginLeft: "-$15",
  },

  "@mdUp": {
    marginBottom: "$80",
    marginLeft: "0",
  },
})

const ImageWithQuoteWrapper = styled("div", {
  marginBottom: "$80",

  "@mdDown": {
    width: "calc(100% + $30)",
    marginBottom: "$40",
    marginLeft: "-$15",
    padding: "$15",
    backgroundColor: "$white",
  },
})

const ImageWrapper = styled("div", {
  textAlign: "left",

  "> *": {
    padding: "0 !important",
  },
})

const HomepageQuoteWrapper = styled("div", {
  "@mdUp": {
    marginBottom: "-$20",
    marginLeft: "-$80",
  },
})

const LinkCardsTitleWrapper = styled("div", {
  marginBottom: "$20",

  "> *": {
    color: "$headerBlue",
  },
})

const LinkCardsWrapper = styled("div", {
  marginBottom: "$80",

  "@mdDown": {
    marginBottom: "$20",
  },
})

export const NewsItemsWrapper = styled("div", {
  marginBottom: "$80",

  "@mdDown": {
    marginBottom: "$40",
  },

  "@lgUp": {
    padding: "0 $30",
  },
})

export default function Index({
  page,
  newsItems,
  footerData,
}: Props): ReactElement {
  return (
    <PublicLayout footerData={footerData}>
      <Head>
        <title>{page.title}</title>
        <meta name="title" content={page.seo.title} />
        <meta name="description" content={page.seo.description} />
      </Head>

      <HomepagePageHeader>
        <H1 data-cy="page-title">{page.title}</H1>
        <P size="extraLarge" data-cy="page-subtitle">
          {page.subtitle}
        </P>
      </HomepagePageHeader>

      <PageWidthContainer>
        <Grid>
          <GridItem col={[2, 10]} medium={[1, 12]}>
            <PageContent data-cy="page-content">
              <Grid columns={10}>
                <GridItem col={[3, 6]} small={[1, 10]}>
                  <Markdown>{page.body}</Markdown>
                </GridItem>
              </Grid>
            </PageContent>
          </GridItem>
        </Grid>

        {!!page.linkCards && (
          <Fragment>
            {!!page.linkCardsTitle && (
              <Grid>
                <GridItem col={[2, 10]} medium={[1, 12]}>
                  <LinkCardsTitleWrapper data-cy="link-cards-title">
                    <H4>{page.linkCardsTitle}</H4>
                  </LinkCardsTitleWrapper>
                </GridItem>
              </Grid>
            )}

            <LinkCardsWrapper data-cy="link-cards">
              <Grid>
                <GridItem col={[2, 10]} medium={[1, 12]}>
                  <LinkCards linkCards={page.linkCards} />
                </GridItem>
              </Grid>
            </LinkCardsWrapper>
          </Fragment>
        )}

        <ImageWithQuoteWrapper>
          <Grid alignItems="end">
            <GridItem col={[1, 6]} medium={[1, 7]} small={[1, 12]}>
              <ImageWrapper>
                <Image {...page.image} />
              </ImageWrapper>
            </GridItem>

            <GridItem col={[7, 6]} medium={[8, 5]} small={[1, 12]}>
              {page.quote && (
                <HomepageQuoteWrapper data-cy="quote">
                  <HomepageQuote {...page.quote} />
                </HomepageQuoteWrapper>
              )}
            </GridItem>
          </Grid>
        </ImageWithQuoteWrapper>
      </PageWidthContainer>

      {!!newsItems.length && (
        <NewsItemsWrapper>
          <NewsItems
            newsItems={newsItems}
            title={<Message id="homepage.news.title" />}
            subtitle={<Message id="homepage.news.subtitle" />}
            linkText={<Message id="homepage.news.button" />}
            linkHref="/nieuws"
          />
        </NewsItemsWrapper>
      )}
      <TopScrollButton />
    </PublicLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ res }) => {
  try {
    setCacheControl(res)

    return {
      props: {
        page: await api.strapi.getSingleTypeByName("homepage"),
        newsItems: await api.strapi.getNewsItems(0, 2),
      },
    }
  } catch (err) {
    return await SSRException(err, res)
  }
}
